import styled from 'styled-components'

const StyledRichText = styled.div`
  padding: 1rem 0;

  img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    margin: 1rem auto;
  }
`
export default StyledRichText
