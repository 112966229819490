import React, { useState } from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'

import { Input } from './common/form'
import Button from './Button'

const FORM_NAME = 'WivForm'

const TwoAside = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
`

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  top: 0;
  max-width: unset;

  h4 {
    margin-top: 0;
  }
`

const CheckboxLabel = styled.label`
  margin-bottom: 20px;
  span {
    font-size: 0.75rem;
    line-height: 1;
    color: #444;
  }
`

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const WivForm = () => {
  // const [consent, setConsent] = useState(true)
  const [state, setState] = useState({
    email: '',
    fname: '',
    lname: '',
    location: '',
    newsletter: true,
  })

  const formRef = React.createRef()

  const submitForm = e => {
    e.preventDefault()

    fetch('/women-in-venture/success', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': FORM_NAME,
        ...state,
        newsletter: JSON.stringify(state.newsletter.toString()),
      }),
    })
      .then(() => {
        const email = formRef.current[1].value
        navigate(`/women-in-venture/success?email=${email}`)
      })
      .catch(e => console.error(e))
  }

  return (
    <Form
      onSubmit={submitForm}
      ref={formRef}
      name={FORM_NAME}
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/women-in-venture/success">
      <h4>Download the 2019 Women in Venture report</h4>
      <input type="hidden" name="form-name" defaultValue={FORM_NAME} />
      <Input
        name="email"
        label="Email"
        required
        value={state.email}
        onChange={e => setState({ ...state, email: e.target.value })}
      />
      <TwoAside>
        <Input
          name="fname"
          label="First Name"
          value={state.fname}
          onChange={e => setState({ ...state, fname: e.target.value })}
        />
        <Input
          name="lname"
          label="Last Name"
          value={state.lname}
          onChange={e => setState({ ...state, lname: e.target.value })}
        />
      </TwoAside>
      <Input
        name="location"
        type="text"
        label="Your Location"
        placeholder="City, State/Province"
        value={state.location}
        onChange={e => setState({ ...state, location: e.target.value })}
      />

      <CheckboxLabel>
        <input
          type="checkbox"
          name="newsletter"
          checked={state.newsletter}
          value={state.newsletter}
          onChange={() => setState({ ...state, newsletter: !state.newsletter })}
        />{' '}
        <span>Subscribe me to the newsletter</span>
      </CheckboxLabel>
      <Button type="submit">Submit</Button>
    </Form>
  )
}
export default WivForm
