import React from 'react'
import styled from 'styled-components'

import { colors } from './theme'
import Link from './common/Link'
import Button from './Button'
import PageWrapper from './PageWrapper'

import _StyledRichText from './StyledRichText'
import WivForm from './WivForm'

const HeroWrapper = styled.div`
  display: flex;
  background: ${colors.primaryLight};
  color: ${colors.primaryBlack};
  padding: 4rem 0 2rem;
  margin-bottom: 60px;
`

const HeroTitle = styled.h1`
  font-size: 3.5rem;
  color: ${colors.primaryBlack};
  line-height: 1;
  margin: 0;
`
const HeroSubtitle = styled.h3`
  color: ${colors.primaryBlack};
  font-weight: normal;
  margin: 0;
  font-size: 1.625rem;
`

const CtaButton = styled(Button)`
  margin-top: 1rem;
`

const HeroCopyBlock = styled.div`
  text-align: center;
`

const FormWrapper = styled.div`
  padding: 80px 40px;
  background: white;
  height: 100%;
  position: relative;
  margin-bottom: -2rem;
  background: ${colors.primaryLight};
`

const FormInnerWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
`

const StyledRichText = styled(_StyledRichText)`
  padding: 0;
  margin-bottom: 60px;

  h2 {
    margin-top: 0;
    line-height: 1;
  }
`

const WIVPage = ({ pageData, reports, hideCta }) => {
  const {
    pageHeader: { heading, subheading, image },
    ctaText,
    abstractRtf,
    bodyRtf,
  } = pageData

  return (
    <>
      <HeroWrapper>
        <PageWrapper>
          <HeroCopyBlock>
            <HeroTitle>{heading}</HeroTitle>
            <HeroSubtitle>{subheading}</HeroSubtitle>
            {!hideCta && <CtaButton hashLink="#documents">{ctaText}</CtaButton>}
          </HeroCopyBlock>
          {image && (
            <img
              src={image.file.url}
              alt={image.title}
              style={{
                display: 'none',
              }}
            />
          )}
          {/* </HeroGrid> */}
        </PageWrapper>
      </HeroWrapper>

      <PageWrapper>
        <StyledRichText
          dangerouslySetInnerHTML={{
            __html: abstractRtf.childContentfulRichText.html,
          }}
        />
        <StyledRichText
          dangerouslySetInnerHTML={{
            __html: bodyRtf.childContentfulRichText.html,
          }}
        />
      </PageWrapper>
      <br id="documents" />
      <FormWrapper>
        <PageWrapper>
          <FormInnerWrapper>
            <WivForm />
            <div>
              <h4 style={{ marginTop: 0 }}>Previous Years' reports</h4>
              <ul>
                {reports
                  .filter(r => r.node.year !== 2019)
                  .map(report => (
                    <li key={report.node.year}>
                      <Link to={`/women-in-venture/${report.node.year}`}>
                        {report.node.year}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </FormInnerWrapper>
        </PageWrapper>
      </FormWrapper>
    </>
  )
}

export default WIVPage
